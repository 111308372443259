
import { Flex, Stack, Heading, Select, Button } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { Header, SideBar, InputField, MultipleInput } from '../components'
import { useHandleFormChange, useHandleArrayInput, useCreateJob } from '../hooks';


export default function CreateJob() {

    let navigate = useNavigate();
    let [job, handleChange] = useHandleFormChange({
        job_title: '',
        job_description: '',
        job_type: '',
        job_location: '',
        area: '',
    });

    let [benefits, setBenefits, handleBenefitsChange] = useHandleArrayInput(['']);
    let [requirements, setRequirements, handleRequirementsChange] = useHandleArrayInput(['']);
    let [responsibilities, setResponsibilities, handleResponsibilitiesChange] = useHandleArrayInput(['']);

    let [status, createJob] = useCreateJob();

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="vagas" />

            <Heading pl={2} fontWeight={600} color='#222f46de' fontFamily='Poppins' fontSize={24} letterSpacing={2} >criar nova</Heading>
            <Flex w='80%' h='auto' direction='row' align='space-between' justify='space-evenly' p={5} pt={3} bgColor='#ffffff38' borderRadius={10} mt={8}>
                <Stack w='45%' spacing={4} p={2}>
                    <InputField
                        placeholder="Nome da vaga *"
                        label="Vaga"
                        name="job_title"
                        onChange={handleChange}
                        disabled={status == 'creating'}
                    />
                    <Select disabled={status == 'creating'} onChange={handleChange} name="job_type" value={job.job_type} placeholder='Tipo da vaga *' size='md' bgColor='white' borderRadius={5} fontSize={12} fontFamily='Poppins' color='gray.500' >
                        <option value='CLT'>CLT</option>
                        <option value='Estágio'>Estágio</option>
                        <option value='Summer'>Summer</option>
                    </Select>
                    <InputField
                        placeholder="Descrição da vaga *"
                        label="Descrição"
                        size="lg"
                        name="job_description"
                        onChange={handleChange}
                        disabled={status == 'creating'}
                    />
                    <InputField
                        placeholder="Área *"
                        label="Área"
                        name="area"
                        onChange={handleChange}
                        disabled={status == 'creating'}
                    />
                    <InputField
                        placeholder="Localização"
                        label="Localização"
                        name="job_location"
                        onChange={handleChange}
                        disabled={status == 'creating'}
                    />
                    <MultipleInput handleChange={handleBenefitsChange} setFields={setBenefits} fields={benefits} label="Benefícios" disabled={status == 'creating'} />
                </Stack>
                <Stack w='45%' spacing={4} p={2}>
                    <MultipleInput handleChange={handleRequirementsChange} setFields={setRequirements} fields={requirements} label="Requisitos" disabled={status == 'creating'} />
                    <MultipleInput handleChange={handleResponsibilitiesChange} setFields={setResponsibilities} fields={responsibilities} label="Responsabilidades" disabled={status == 'creating'} />
                    <Flex w={220} justify='space-between'>
                        <Button
                            fontFamily={'Poppins'}
                            fontSize={12}
                            w={100}
                            letterSpacing={1}
                            bgColor={'#b73d3d'}
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                                boxShadow: 'xl',
                            }}
                            disabled={status == 'creating'}
                            _active={{bgColor:'secondary.main'}}
                            onClick={() => navigate('/managejobs')}
                        >
                            CANCELAR
                        </Button>
                        <Button
                            fontFamily={'Poppins'}
                            fontSize={12}
                            w={100}
                            letterSpacing={1}
                            bgColor={'#3e9b27'}
                            color={'white'}
                            _hover={{
                                bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                                boxShadow: 'xl',
                            }}
                            disabled={status == 'creating'}
                            _active={{bgColor:'secondary.main'}}
                            onClick={() => {
                                createJob({
                                    ...job,
                                    job_benefits: benefits.filter(n => n !== ''),
                                    job_requirements: requirements.filter(n => n !== ''),
                                    job_responsibilities: responsibilities.filter(n => n !== ''),
                                }, navigate
                            )}}
                        >
                            SALVAR
                        </Button>
                    </Flex>
                </Stack>
            </Flex>

        </Flex>
    );

}