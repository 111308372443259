import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Text, Button} from '@chakra-ui/react';


const useChallengeEvaluation = (stage) => {

    let [status, setStatus] = useState('');
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [edition, setEdition] = useState(null)

    let team_status = ""

    if (stage === '#firststage') {
        team_status = "registered"
    } else if (stage === '#secondstage' || stage === '#mentoring') {
        team_status = "approved_1"
    }

    useEffect(() => {

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                var user = await Auth.currentAuthenticatedUser();
                const result = await API.get("CHALLENGE_TEAMS", "/teams/evaluator/" + `2022/${team_status}/${user.attributes.email}`, myInit);
                const ed = await API.get("CHALLENGE_EDITION", "/edition/2022", myInit);
                setEdition(ed)
                setData(result);
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.resonse.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [stage]);


    
    const evaluate1stStage = async (team) => {

        setLoading(true)
        let body = {
            login_email : team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                first_stage : {
                    status : "evaluated",
                    video_teaser_link : team.video_link,
                    clarity : team.clarity,
                    criativity : team.criativity,
                    extra_information : team.extra_information,
                    logical_fundamentals : team.logical_fundamentals,
                    message : team.message,
                    participation : team.participation,
                    salesmanship : team.salesmanship,
                    technique : team.technique,
                    visual : team.visual
                }
            }
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/update', myInit);
            setLoading(false)
            window.location.reload()
            alert('Time avaliado com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
                            
    }

    const evaluate2ndStage = async (team) => {

        setLoading(true)

        if (edition.active_stage != '2-evaluation_open') {
            setLoading(false)
            return alert('Avaliações ainda não habilitadas.')
        }

        let body = {
            login_email : team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                second_stage : {
                    ...team.second_stage,
                    status: "evaluated",
                    im_clarity : team.im_clarity,
                    im_criativity : team.im_criativity,
                    im_extra_information : team.im_extra_information,
                    im_logical_fundamentals : team.im_logical_fundamentals,
                    im_message : team.im_message,
                    im_participation : team.im_participation,
                    im_salesmanship : team.im_salesmanship,
                    im_technique : team.im_technique,
                    im_visual : team.im_visual,
                    valuation_clarity: team.valuation_clarity,
                    valuation_criativity: team.valuation_criativity,
                    valuation_extra_information: team.valuation_extra_information,
                    valuation_logical_fundamentals: team.valuation_logical_fundamentals,
                    valuation_message: team.valuation_message,
                    valuation_participation: team.valuation_participation,
                    valuation_salesmanship: team.valuation_salesmanship,
                    valuation_technique: team.valuation_technique,
                    valuation_visual: team.valuation_visual
                }
            }
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/update', myInit);
            setLoading(false)
            window.location.reload()
            alert('Time avaliado com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
                            
    }


    const evaluateMentoring = async (team) => {
        setLoading(true)

        let members = [...team.members]
        members[0].english = team.m1_english
        members[1].english = team.m2_english
        members[2].english = team.m3_english

        let body = {
            login_email : team.login_email,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                members : members
            }
        }

        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/update', myInit);
            setLoading(false)
            window.location.reload()
            alert('Time avaliado com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }

    var rows = []
    var columns = []

    if (data) {

        if (stage == "#firststage") {
            [ rows, columns ] = prepare1stStageData(data, evaluate1stStage, loading);
        } else if (stage == "#secondstage") {
            [ rows, columns ] = prepare2ndStageData(data, evaluate2ndStage, loading);
        } else if (stage == "#mentoring") {
            [ rows, columns ] = prepareMentoringStageData(data, evaluateMentoring, loading);
        }

        
    }

    return [ status, { rows, columns }, loading ];
        
};



function prepare1stStageData(data, evaluate1stStage, loading) {

    let rows = []
    let columns = []

    for (let i = 0; i < data.length; i++) {
        rows.push({
            id: i,
            team_name : data[i].team_name,
            video_link : data[i].first_stage ? data[i].first_stage.video_teaser_link : '',
            clarity : data[i].first_stage && data[i].first_stage.clarity ? data[i].first_stage.clarity : -1,
            criativity : data[i].first_stage && data[i].first_stage.criativity ? data[i].first_stage.criativity : -1,
            extra_information : data[i].first_stage && data[i].first_stage.extra_information ? data[i].first_stage.extra_information : -1,
            logical_fundamentals : data[i].first_stage && data[i].first_stage.logical_fundamentals ? data[i].first_stage.logical_fundamentals : -1,
            message : data[i].first_stage && data[i].first_stage.message ? data[i].first_stage.message : -1,
            participation : data[i].first_stage && data[i].first_stage.participation ? data[i].first_stage.participation : -1,
            salesmanship : data[i].first_stage && data[i].first_stage.salesmanship ? data[i].first_stage.salesmanship : -1,
            technique : data[i].first_stage && data[i].first_stage.technique ? data[i].first_stage.technique : -1,
            visual : data[i].first_stage && data[i].first_stage.visual ? data[i].first_stage.visual : -1,
            login_email : data[i].login_email,
        })
    }

    columns = [
        { field: 'team_name', headerName: 'Equipe', width: 200 },
        { field: 'video_link', width: 160,
            headerName: 'Video Teaser',
            renderCell: (params) => {
                if(params.row.video_link == ""){
                    return(
                        <Text>
                            Não enviado
                        </Text>
                    );
                } else {
                    return (
                        <Button 
                            fontFamily={'Poppins'}
                            w={100}
                            h={"25px"}
                            ml={4}
                            fontSize={12}
                            letterSpacing={1}
                            bgGradient="linear-gradient(to right, #627494, #3d4759)"
                            color={'white'}
                            _hover={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            _active={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            onClick={() => window.open(params.row.video_link, "_blank")}
                        >
                            Ver vídeo
                        </Button>
                        
                    );
                }
            }
        },
        { field: 'clarity', type: 'number', editable: true, headerName: 'Clareza', width: 130 },
        { field: 'criativity', type: 'number', editable: true, headerName: 'Criatividade', width: 160 },
        { field: 'extra_information', type: 'number', editable: true, headerName: 'Inf. Extras', width: 150 },
        { field: 'logical_fundamentals', type: 'number', editable: true, headerName: 'Fund. Lógicos', width: 170 },
        { field: 'message', type: 'number', editable: true, headerName: 'Mensagem', width: 150 },
        { field: 'participation', type: 'number', editable: true, headerName: 'Participação', width: 160 },
        { field: 'salesmanship', type: 'number', editable: true, headerName: 'Salesmanship', width: 170 },
        { field: 'technique', type: 'number', editable: true, headerName: 'Técnica', width: 140 },
        { field: 'visual', type: 'number', editable: true, headerName: 'Visual', width: 120 },
        { field: 'approve', width: 150,
            headerName: 'Enviar notas', width: 160,
                renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        h={"25px"}
                        ml={4}
                        fontSize={12}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #627494, #3d4759)"
                        color={'white'}
                        _hover={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        disabled={loading || (params.row.clarity <0 || params.row.clarity > 5) || (params.row.criativity <0 || params.row.criativity > 5) || (params.row.extra_information <0 || params.row.extra_information > 5) || (params.row.logical_fundamentals <0 || params.row.logical_fundamentals > 5) || (params.row.message <0 || params.row.message > 5) || (params.row.participation <0 || params.row.participation > 5) || (params.row.salesmanship <0 || params.row.salesmanship > 5) || (params.row.technique <0 || params.row.technique > 5) || (params.row.visual <0 || params.row.visual > 5)}
                        onClick={() => evaluate1stStage(params.row)}
                    >
                        Enviar notas
                    </Button>
                    
                );
                }
            }
        ];

        return [rows, columns]
}

function prepare2ndStageData(data, evaluate2ndStage, loading) {

    let rows = []
    let columns = []

    for (let i = 0; i < data.length; i++) {
        rows.push({
            id: i,
            team_name : data[i].team_name,
            im_link : data[i].second_stage && data[i].second_stage.im_link,
            valuation_link : data[i].second_stage && data[i].second_stage.valuation_link,

            im_clarity : data[i].second_stage && data[i].second_stage.im_clarity ? data[i].second_stage.im_clarity : -1,
            im_criativity : data[i].second_stage && data[i].second_stage.im_criativity ? data[i].second_stage.im_criativity : -1,
            im_extra_information : data[i].second_stage && data[i].second_stage.im_extra_information ? data[i].second_stage.im_extra_information : -1,
            im_logical_fundamentals : data[i].second_stage && data[i].second_stage.im_logical_fundamentals ? data[i].second_stage.im_logical_fundamentals : -1,
            im_message : data[i].second_stage && data[i].second_stage.im_message ? data[i].second_stage.im_message : -1,
            im_participation : data[i].second_stage && data[i].second_stage.im_participation ? data[i].second_stage.im_participation : -1,
            im_salesmanship : data[i].second_stage && data[i].second_stage.im_salesmanship ? data[i].second_stage.im_salesmanship : -1,
            im_technique : data[i].second_stage && data[i].second_stage.im_technique ? data[i].second_stage.im_technique : -1,
            im_visual : data[i].second_stage && data[i].second_stage.im_visual ? data[i].second_stage.im_visual : -1,

            valuation_clarity : data[i].second_stage && data[i].second_stage.valuation_clarity ? data[i].second_stage.valuation_clarity : -1,
            valuation_criativity : data[i].second_stage && data[i].second_stage.valuation_criativity ? data[i].second_stage.valuation_criativity : -1,
            valuation_extra_information : data[i].second_stage && data[i].second_stage.valuation_extra_information ? data[i].second_stage.valuation_extra_information : -1,
            valuation_logical_fundamentals : data[i].second_stage && data[i].second_stage.valuation_logical_fundamentals ? data[i].second_stage.valuation_logical_fundamentals : -1,
            valuation_message : data[i].second_stage && data[i].second_stage.valuation_message ? data[i].second_stage.valuation_message : -1,
            valuation_participation : data[i].second_stage && data[i].second_stage.valuation_participation ? data[i].second_stage.valuation_participation : -1,
            valuation_salesmanship : data[i].second_stage && data[i].second_stage.valuation_salesmanship ? data[i].second_stage.valuation_salesmanship : -1,
            valuation_technique : data[i].second_stage && data[i].second_stage.valuation_technique ? data[i].second_stage.valuation_technique : -1,
            valuation_visual : data[i].second_stage && data[i].second_stage.valuation_visual ? data[i].second_stage.valuation_visual : -1,

            second_stage: data[i].second_stage,
            login_email : data[i].login_email
        })
    }

    columns = [
        { field: 'team_name', headerName: 'Equipe', width: 200 },
        { field: 'im_link', width: 160,
            headerName: 'Video IM',
            renderCell: (params) => {
                if(params.row.im_link == ""){
                    return(
                        <Text>
                            Não enviado
                        </Text>
                    );
                } else {
                    return (
                        <Button 
                            fontFamily={'Poppins'}
                            w={100}
                            h={"25px"}
                            ml={4}
                            fontSize={12}
                            letterSpacing={1}
                            bgGradient="linear-gradient(to right, #627494, #3d4759)"
                            color={'white'}
                            _hover={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            _active={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            onClick={() => window.open(params.row.im_link, "_blank")}
                            disabled={!params.row.im_link}
                        >
                            Ver vídeo
                        </Button>
                        
                    );
                }
            }
        },
        { field: 'valuation_link', width: 160,
            headerName: 'Video Valuation',
            renderCell: (params) => {
                if (params.row.valuation_link == ""){
                    return(
                        <Text>
                            Não enviado
                        </Text>
                    );
                } else {
                    return (
                        <Button 
                            fontFamily={'Poppins'}
                            w={100}
                            h={"25px"}
                            ml={4}
                            fontSize={12}
                            letterSpacing={1}
                            bgGradient="linear-gradient(to right, #627494, #3d4759)"
                            color={'white'}
                            _hover={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            _active={{
                                bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                boxShadow: 'xl',
                            }}
                            onClick={() => window.open(params.row.valuation_link, "_blank")}
                            disabled={!params.row.valuation_link}
                        >
                            Ver vídeo
                        </Button>
                        
                    );
                }
            }
        },

        { field: 'im_clarity', type: 'number', editable: true, headerName: 'Clareza (IM)', width: 200 },
        { field: 'im_criativity', type: 'number', editable: true, headerName: 'Criatividade (IM)', width: 200 },
        { field: 'im_extra_information', type: 'number', editable: true, headerName: 'Inf. Extras (IM)', width: 200 },
        { field: 'im_logical_fundamentals', type: 'number', editable: true, headerName: 'Fund. Lógicos (IM)', width: 200 },
        { field: 'im_message', type: 'number', editable: true, headerName: 'Mensagem (IM)', width: 200 },
        { field: 'im_participation', type: 'number', editable: true, headerName: 'Participação (IM)', width: 200 },
        { field: 'im_salesmanship', type: 'number', editable: true, headerName: 'Salesmanship (IM)', width: 200 },
        { field: 'im_technique', type: 'number', editable: true, headerName: 'Técnica (IM)', width: 200 },
        { field: 'im_visual', type: 'number', editable: true, headerName: 'Visual (IM)', width: 200 },

        { field: 'valuation_clarity', type: 'number', editable: true, headerName: 'Clareza (Valuation)', width: 200 },
        { field: 'valuation_criativity', type: 'number', editable: true, headerName: 'Criatividade (Valuation)', width: 200 },
        { field: 'valuation_extra_information', type: 'number', editable: true, headerName: 'Inf. Extras (Valuation)', width: 200 },
        { field: 'valuation_logical_fundamentals', type: 'number', editable: true, headerName: 'Fund. Lógicos (Valuation)', width: 200 },
        { field: 'valuation_message', type: 'number', editable: true, headerName: 'Mensagem (Valuation)', width: 200 },
        { field: 'valuation_participation', type: 'number', editable: true, headerName: 'Participação (Valuation)', width: 200 },
        { field: 'valuation_salesmanship', type: 'number', editable: true, headerName: 'Salesmanship (Valuation)', width: 200 },
        { field: 'valuation_technique', type: 'number', editable: true, headerName: 'Técnica (Valuation)', width: 200 },
        { field: 'valuation_visual', type: 'number', editable: true, headerName: 'Visual (Valuation)', width: 200 },

        { field: 'approve', width: 150,
            headerName: 'Enviar notas', width: 160,
                renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        h={"25px"}
                        ml={4}
                        fontSize={12}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #627494, #3d4759)"
                        color={'white'}
                        _hover={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        disabled={loading || disableEvaluate2ndStage(params.row)}
                        onClick={() => evaluate2ndStage(params.row)}
                    >
                        Enviar notas
                    </Button>
                    
                );
                }
            }
        ];

        return [rows, columns]
}

function prepareMentoringStageData(data, evaluateMentoringStage, loading) {

    let rows = []
    let columns = []

    for (let i = 0; i < data.length; i++) {
        rows.push({
            id: i,
            team_name : data[i].team_name,
            login_email : data[i].login_email,
            m1_name : data[i].members[0].name,
            m1_english : data[i].members[0].english ? data[i].members[0].english : -1,
            m2_name : data[i].members[1].name,
            m2_english: data[i].members[1].english ? data[i].members[1].english : -1,
            m3_name : data[i].members[2].name,
            m3_english: data[i].members[2].english ? data[i].members[2].english : -1,

            members: data[i].members
        })
    }

    columns = [
        { field: 'team_name', headerName: 'Equipe', width: 200 },
        { field: 'm1_name', headerName: 'Membro 1', width: 160 },
        { field: 'm1_english', type: 'number', editable: true, headerName: 'M1 - Inglês', width: 150 },
        { field: 'm2_name', headerName: 'Membro 2', width: 160 },
        { field: 'm2_english', type: 'number', editable: true, headerName: 'M2 - Inglês', width: 150 },
        { field: 'm3_name', headerName: 'Membro 3', width: 160 },
        { field: 'm3_english', type: 'number', editable: true, headerName: 'M3 - Inglês', width: 150 },
        

        { field: 'evaluate', width: 150,
            headerName: 'Enviar notas', width: 160,
                renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        h={"25px"}
                        ml={4}
                        fontSize={12}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #627494, #3d4759)"
                        color={'white'}
                        _hover={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                            boxShadow: 'xl',
                        }}
                        disabled={loading || (params.row.m1_english < 0 || params.row.m1_english > 5) || (params.row.m2_english < 0 || params.row.m2_english > 5) || (params.row.m3_english < 0 || params.row.m3_english > 5)}
                        onClick={() => evaluateMentoringStage(params.row)}
                    >
                        Enviar notas
                    </Button>
                    
                );
                }
            }
        ];

        return [rows, columns]
}

function disableEvaluate2ndStage(row) {
    let keys = ['clarity', 'criativity', 'extra_information', 'logical_fundamentals', 'message', 'participation', 'salesmanship', 'technique', 'visual'];
    let types = ['im', 'valuation'];

    for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < types.length; j++) {
            if (row[types[j] + '_' + keys[i]] < 0 || row[types[j] + '_' + keys[i]] > 5) {
                return true;
            }
        }
    }
    return false
}

export default useChallengeEvaluation;