import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import Amplify, { Storage, API } from 'aws-amplify';

import { useConfigureAmplify } from '../index';

// Custom hook that handles form submit
const useHandleFormSubmit = (initialState) => {

    useConfigureAmplify(0)
    
    const [err, setErr] = useState({...initialState});

    const submit = async (values, setIsLoading, navigate, job_id) => {

        setIsLoading(true)

        // Validate form
        let newErr = {...initialState};
        let hasErr = false;

        if (values.name.length < 5) {
            newErr.name = 'Nome completo é obrigatório';
            hasErr = true;
        }
        if (values.email.length == 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            newErr.email = 'Email inválido';
            hasErr = true;
        }
        if (values.phone.length < 10) {
            newErr.phone = 'Telefone inválido, formato: xx xxxx-xxxx';
            hasErr = true;
        }
        if (values.linkedin.length < 10) {
            newErr.linkedin = 'Informe o link do seu perfil no LinkedIn';
            hasErr = true;
        }
        if (values.birth_date.length != 10) {
            newErr.birth_date = 'Insira data no formato dd/mm/aaaa';
            hasErr = true;
        }
        if (values.university.length == 0) {
            newErr.university = 'Insira a universidade de origem';
            hasErr = true;
        }
        if (values.course.length == 0) {
            newErr.course = 'Insira o curso de graduação';
            hasErr = true;
        }
        if (values.graduation_year.length != 5) {
            newErr.graduation_year = 'Insira o mês e ano de conclusão do curso de graduação: dd/mm';
            hasErr = true;
        }
        if (values.file_name.length == 0 || values.file_name.split('.').pop() != 'pdf') {
            newErr.file_name = 'Anexe um arquivo pdf com seu currículo';
            hasErr = true;
        }

        // If there are errors, set the error state
        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }
        
        let code = uuidv4().split('-')[0];
        const filename = values.name.split(" ").join("_") + "_" + values.email.split("@")[0] + '_' + code + '.pdf'

        try {
            const result = await Storage.put(filename, values.file);
        } catch (err) {
            setIsLoading(false)
            return alert("Erro ao realizar o upload do CV, tente novamente mais tarde ou entre em contato através do email: semanadomea@igcp.com.br")
        }

        try {
            const res = await API.post('APPLICANTS', '/applicants/create', {
                body: {
                    applicant_email:            values.email,
                    applicant_name:             values.name,
                    applicant_phone:            values.phone,
                    applicant_linkedin:         values.linkedin,
                    applicant_birth_date:       values.birth_date,
                    applicant_university:       values.university,
                    applicant_course:           values.course,
                    applicant_graduation_year:  values.graduation_year,
                    applicant_cv_filename:      filename,
                    job_id:                     job_id
                }
            });
            setIsLoading(false)
            return navigate('/success');
        } catch (err) {
            console.log(err)
            if (err.response.status == 409) {
                setIsLoading(false)
                return alert("Email já cadastrado")
            } else if (err.response.status == 500) {
                setIsLoading(false)
                return alert("Erro interno do servidor, tente novamente mais tarde")
            }
        }
        
    }

    return [err, submit];    

}

export default useHandleFormSubmit;