import { Heading, Flex, Button, Divider } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';

import { Header, EvaluatorMenu, InputField } from '../components'
import { useChallengeEvaluation } from '../hooks'

export default function EvaluateChallenge() {

    let navigate = useNavigate()
    let location = useLocation()
    let stage = location.hash == "#firststage" ? "Video Teaser" : location.hash == '#secondstage' ? "IM & Valuation" : "Mentoria"
    
    let [ status, { rows, columns }, loading ] = useChallengeEvaluation(location.hash)

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <EvaluatorMenu navigate={navigate} />
            <Header title={`Challenge: Avaliação - ${stage}`} />

            { 
                stage == "Mentoria" ? 

                <>
                    <Flex w='90%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={1} pl={4} mt={10}>
                        <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Horários Disponibilizados para Mentoria</Heading>
                    </Flex>
                    <Flex p={6} w="90%" borderRadius={10} minH={200} bgColor="#ffffff8c" mt="40px" direction="row" align="center" justify="space-evenly">
                        <Flex direction="column" align="flex-start" justify="space-between" w="30%">
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 09:00 - 09:15</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 09:20 - 09:35</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 09:40 - 09:55</Heading>
                        </Flex>
                        <Flex direction="column" align="flex-start" justify="space-between" w="30%">
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 10:00 - 10:15</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 10:20 - 10:35</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 10:40 - 10:55</Heading>
                        </Flex>
                        <Flex direction="column" align="flex-start" justify="space-between" w="30%">
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 11:00 - 11:15</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 11:20 - 11:35</Heading>
                            <Heading fontWeight={300} color='#222f46de' fontFamily='Poppins' fontSize={16} letterSpacing={"1px"} mb={5}>30/06/2022 | 11:40 - 11:55</Heading>
                        </Flex>
                    </Flex>
                    <Flex w='90%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={1} pl={4} mt={10} mb={6}>
                        <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Avaliar Inglês</Heading>
                    </Flex>
                    <DataGrid style={{width:'90%', backgroundColor:'#ffffff94', padding:'20px', borderRadius:10}} rows={rows} columns={columns} pageSize={20} rowHeight={40} components={{
                        Toolbar: GridToolbar,
                    }}  />
                </>

                :

                <DataGrid style={{width:'90%', backgroundColor:'#ffffff94', padding:'20px', borderRadius:10}} rows={rows} columns={columns} pageSize={20} rowHeight={40} components={{
                    Toolbar: GridToolbar,
                }}  />
            }

        </Flex>
    );
    

    
}