import { Text } from '@chakra-ui/react';
import { useState } from 'react';
import { MdAddCircle } from 'react-icons/md';

import { InputField } from '../index';
import { useHandleFormChange } from '../../hooks';

export default function MultipleInput({ fields, label, disabled, setFields, handleChange }) {
    
    if (!fields) return null;
    
    return(
        <>
            <Text mt={1} fontWeight={600} fontSize={12}>{label}</Text>
           {
                fields.map((field, index) => {
                    return(
                        <InputField key={index} value={field} size="sm" disabled={disabled} onChange={(e) => handleChange(e, index)} />
                    );
                })
           }
           <MdAddCircle fontSize={28} cursor={!disabled?'pointer':''} onClick={!disabled?() => setFields([...fields, '']):null} />
        </>
    );

}