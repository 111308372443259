import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


// Custom hook to fetch data from the API
// @param {string} endpoint - the path to the API endpoint
const useCreateJob = () => {

    const [status, setStatus] = useState('idle');


    const createJob = async (body, navigate) => {
        
        setStatus('creating');
        try {
            let myInit = { 
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body: body
            }
            const result = await API.post("JOBS", 'jobs/create', myInit);
            setStatus('idle');
            alert('Vaga criada com sucesso');
            return navigate('/managejobs')
        } catch (error) {
            console.log(error)
            setStatus('idle');
            alert('Erro ao criar vaga, tente novamente mais tarde.');
        }
    };
        
    
    return [ status, createJob ];
};

export default useCreateJob;