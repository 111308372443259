import { useState } from 'react';
import {v4 as uuidv4} from 'uuid';
import Amplify, { Storage } from 'aws-amplify';

import { useConfigureAmplify } from '../index';

// Custom hook that handles form submit
const useGetApplicantCV = () => {

    useConfigureAmplify(1)
    
    const getCv = async (filename) => {

        try {
            const cv = await Storage.get(filename, {level: 'public'})
            return window.open(cv, '_blank');
        } catch (error) {
            console.log(error)
            return alert('CV não encontrado')
        }
        
    }

    return getCv;    

}

export default useGetApplicantCV;