
import { Heading, Flex, Stack, Select, Button } from '@chakra-ui/react'
import ReactDataGrid from '@inovua/reactdatagrid-community'
import { useNavigate, useParams } from 'react-router-dom'
import '@inovua/reactdatagrid-community/index.css'
import { BsFillArrowRightSquareFill, BsFillArrowDownSquareFill } from 'react-icons/bs'
import { BiEdit } from 'react-icons/bi'
import { useState } from 'react';

import { Header, SideBar, Loading, InputField, MultipleInput } from '../components'
import { useFetch, useGetApplicantCV, useHandleFormChange, useHandleArrayInput, useUpdateJob } from '../hooks'
import '../styles/datagrid.css'


export default function JobDetails() {

    let { job_id } = useParams();
    let navigate = useNavigate();

    let [ status, data ] = useFetch("JOBS", '/job/' + job_id);
    let [ applicants_status, applicants ] = useFetch("APPLICANTS", '/applicants/' + job_id);
    let [enableEdit, setEnableEdit] = useState(false);

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title={data && data.job_title ? data.job_title : 'vagas'} />

            {
                applicants_status === 'fetching' ? <Loading h='400px' w='100%' /> :
            
                <Flex w='100%' h='auto' direction='column' align='flex-start' justify='space-between' pt={5} pb={2} pl={8}>
                    <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Inscrições Recebidas</Heading>
                    <ApplicantsTable applicants={applicants} />
                </Flex>
            }

            {
                status === 'fetching' ? <Loading h='400px' w='100%' /> :
            
                <Flex w='100%' h='auto' direction='column' align='flex-start' justify='space-between' pt={8} pb={2} pl={8}>
                    <Flex>
                        <Heading pr={5} fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Detalhes da Vaga</Heading>
                        <BiEdit fontSize={30} color='#d5a741' cursor='pointer' onClick={() => setEnableEdit(true)} />
                    </Flex>
                    <EditJob data={data} enableEdit={enableEdit} disable={() => setEnableEdit(false)} />
                </Flex>
            }

        </Flex>
    );

}

function ApplicantsTable({ applicants }) {
    
    let getCv = useGetApplicantCV();

    const columns = [
        { name: 'applicant_name', header: 'Nome', defaultWidth: 150},
        { name: 'applicant_university', header: 'Faculdade', defaultWidth: 130},
        { name: 'applicant_course', header: 'Curso', defaultWidth: 200},
        { name: 'applicant_graduation_year', header: 'Prev de Grad', defaultWidth: 140},
        { name: 'applicant_email', header: 'Email', defaultWidth: 200},
        { name: 'applicant_phone', header: 'Telefone', defaultWidth: 140},
        { name: 'applicant_linkedin', header: 'Linkedin', defaultWidth: 110, render: 
            (row) => <BsFillArrowRightSquareFill size={20} cursor='pointer' color='secondary.main' onClick={() => window.open(row.data.applicant_linkedin, '_blank')} />
        },
        { name: 'applicant_cv_filename', header: 'CV', defaultWidth: 80, render: 
            (row) => <BsFillArrowDownSquareFill size={20} cursor='pointer' color='secondary.main' onClick={() => getCv(row.data.applicant_cv_filename)} />
        },
        { name: 'created_at', header: 'Inscrição', defaultWidth: 120, render: ({ value }) => value.split('T')[0] },
    ];

    return(
        <Flex w='100%' maxH={400} direction='row' align='center' justify='space-between' p={5} pt={3} bgColor='#ffffff38' borderRadius={10} mt={8}>
            <ReactDataGrid
                idProperty="applicant_id"
                columns={columns}
                dataSource={applicants}
                rowHeight={35}
                showHoverRows={true}
                pagination
                defaultLimit={10}
                style={{
                    width: '100%',
                    height: '500px',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    maxHeight: '350px',
                }}
            />
        </Flex>
    );
}


function EditJob({ data, enableEdit, disable }) {

    let [job, handleChange] = useHandleFormChange(data);
    let [benefits, setBenefits, handleBenefitsChange] = useHandleArrayInput(job.job_benefits);
    let [requirements, setRequirements, handleRequirementsChange] = useHandleArrayInput(job.job_requirements);
    let [responsibilities, setResponsibilities, handleResponsibilitiesChange] = useHandleArrayInput(job.job_responsibilities);
    let [status, updateJob] = useUpdateJob()

    return(
        <Flex w='100%' h='auto' direction='row' align='space-between' justify='space-between' p={5} pt={3} bgColor='#ffffff38' borderRadius={10} mt={8}>
            <Stack w='45%' spacing={4} p={2}>
                <InputField
                    placeholder="Nome da vaga *"
                    label="Vaga"
                    name="job_title"
                    value={job.job_title}
                    onChange={handleChange}
                    disabled={!enableEdit || status == 'updating'}
                />
                <Select disabled={!enableEdit || status == 'updating'} onChange={handleChange} name="job_type" value={job.job_type} placeholder='Tipo da vaga *' size='md' bgColor='white' borderRadius={5} fontSize={12} fontFamily='Poppins' color='gray.500' >
                    <option value='CLT'>CLT</option>
                    <option value='Estágio'>Estágio</option>
                    <option value='Summer'>Summer</option>
                </Select>
                <InputField
                    placeholder="Descrição da vaga *"
                    label="Descrição"
                    size="lg"
                    name="job_description"
                    value={job.job_description}
                    onChange={handleChange}
                    disabled={!enableEdit || status == 'updating'}
                />
                <InputField
                    placeholder="Área *"
                    label="Área"
                    name="area"
                    value={job.area}
                    onChange={handleChange}
                    disabled={!enableEdit || status == 'updating'}
                />
                <InputField
                    placeholder="Localização"
                    label="Localização"
                    name="job_location"
                    value={job.job_location}
                    onChange={handleChange}
                    disabled={!enableEdit || status == 'updating'}
                />
                <MultipleInput handleChange={handleBenefitsChange} setFields={setBenefits} fields={benefits} label="Benefícios" disabled={!enableEdit || status == 'updating'} />
            </Stack>
            <Stack w='45%' spacing={4} p={2}>
                <MultipleInput handleChange={handleRequirementsChange} setFields={setRequirements} fields={requirements} label="Requisitos" disabled={!enableEdit || status == 'updating'} />
                <MultipleInput handleChange={handleResponsibilitiesChange} setFields={setResponsibilities} fields={responsibilities} label="Responsabilidades" disabled={!enableEdit || status == 'updating'} />
                <Flex w={220} justify='space-between'>
                    <Button
                        fontFamily={'Poppins'}
                        fontSize={12}
                        w={100}
                        letterSpacing={1}
                        bgColor={'#b73d3d'}
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                            boxShadow: 'xl',
                        }}
                        disabled={!enableEdit || status == 'updating'}
                        _active={{bgColor:'secondary.main'}}
                        onClick={() => disable()}
                    >
                        CANCELAR
                    </Button>
                    <Button
                        fontFamily={'Poppins'}
                        fontSize={12}
                        w={100}
                        letterSpacing={1}
                        bgColor={'#3e9b27'}
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                            boxShadow: 'xl',
                        }}
                        disabled={!enableEdit || status == 'updating'}
                        _active={{bgColor:'secondary.main'}}
                        onClick={() => {
                            let id = job.job_id;
                            delete job.job_id
                            delete job.created_at
                            delete job.updated_at
                            delete job.job_benefits
                            delete job.job_requirements
                            delete job.job_responsibilities
                             updateJob({
                                job_id: id,
                                update: {
                                    ...job,
                                    job_benefits: benefits.filter(n => n !== ''),
                                    job_requirements: requirements.filter(n => n !== ''),
                                    job_responsibilities: responsibilities.filter(n => n !== ''),
                                }
                            }
                        )}}
                    >
                        SALVAR
                    </Button>
                </Flex>
            </Stack>
        </Flex>
    );

}