import { Box, Image, Stack, Heading, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

import { Blur } from '../components'
import logo from '../assets/logo.png'

export default function Success() {

    let navigate = useNavigate()

    return (

        <Box position={'relative'} minH='100vh' bgColor='#222f46db'>
            <Stack
                direction={['column', 'column', 'row']}
                align={['center', 'center', 'center']}
                justify={['center', 'center', 'space-evenly']}
                minH='100vh'
                maxW={'100%'}
                spacing={8}
                p={10}
                pt={[50, 50, 10]}
                overflow='auto'
            >
                
                {/* Page title and igc logo */}
                <Stack spacing={{ base: 10, md: 20 }} align='center'>
                    <Heading
                        lineHeight={1.1}
                        fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
                        fontFamily='Poppins'
                        fontWeight={500}
                        textAlign='center'
                        color='white'
                    >
                        Inscrição realizada com sucesso!{' '}
                    </Heading>
                    <Heading fontSize={26} fontWeight={200} color="white" textAlign={'center'}>Mais detalhes serão enviados via email, se necessário.</Heading>
                    <Image src={logo} alt='logo' width='190px' height='auto' cursor='pointer' ml={10} onClick={() => navigate('/')}/>
                </Stack>

            </Stack>
            
            <Blur
                position={'absolute'}
                top={-10}
                left={-10}
                style={{ filter: 'blur(85px)' }}
            />

        </Box>
    );
}