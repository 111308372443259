import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom'

import Job from './pages/Job';
import Homepage from './pages/Homepage';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Success from './pages/Success';
import Opportunities from './pages/Opportunities';
import ManageJobs from './pages/ManageJobs';
import JobDetails from './pages/JobDetails';
import CreateJob from './pages/CreateJob';
import ManageChallenge from './pages/ManageChallenge';
import EvaluateChallenge from './pages/EvaluateChallenge';
import { PrivateRoute } from './components';

function Router(){
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Homepage />}/>
                <Route path="/vagas" element={<Opportunities />}/>
                <Route path="/success" element={<Success />}/>
                <Route path="/login" element={<Login />}/>
                <Route path="/vaga/:job_id" element={<Job />}/>
                <Route path='/managejobs' element={
                    <PrivateRoute path='/managejobs'>
                        <ManageJobs />
                    </PrivateRoute>
                }/>
                <Route path='/managechallenge' element={
                    <PrivateRoute path='/managechallenge'>
                        <ManageChallenge />
                    </PrivateRoute>
                }/>
                <Route path='/job_details/:job_id' element={
                    <PrivateRoute path='/job_details/:job_id'>
                        <JobDetails />
                    </PrivateRoute>
                }/>
                <Route path='/createjob' element={
                    <PrivateRoute path='/createjob'>
                        <CreateJob />
                    </PrivateRoute>
                }/>
                <Route path='/evaluatechallenge' element={
                    <PrivateRoute path='/evaluatechallenge'>
                        <EvaluateChallenge />
                    </PrivateRoute>
                }/>
                <Route path='*' element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default Router;
