
import { Heading, Flex, Button, Table, TableContainer, Thead, Tbody, Tr, Td, Th } from '@chakra-ui/react'
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { useNavigate } from 'react-router-dom'

import { Header, SideBar, Loading } from '../components'
import { useFetch, useUpdateChallengeEdition, useChallenge1stStageHR, useChallenge2ndStageHR } from '../hooks'

export default function ManageJobs() {

    let navigate = useNavigate()

    let [status, data, loading, updateChallengeEdition] = useUpdateChallengeEdition()
    let [ status1, { rows1, columns1 }, disable1, endFirstStage, ldn ] = useChallenge1stStageHR(data.active_stage)
    let [ status2, { rows2, columns2 }, disable2, endSecondStage, ldn2 ] = useChallenge2ndStageHR(data.active_stage)

    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="Challenge" />
            {status == 'fetching' ? <Loading w='100%' h='auto' /> :
            <>
                <Flex w='90%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={1} pl={4}>
                    <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Gerenciar Challenge</Heading>
                    <Button
                        fontFamily={'Poppins'}
                        w={150}
                        fontSize={16}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #616772, #3a588d)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                            boxShadow: 'xl',
                        }}
                        _active={{bgColor:'secondary.main'}}
                    >
                        NOVO
                    </Button>
                </Flex>
                <Flex bgColor="#ffffff8c" w={"90%"} p={6} borderRadius={10} mt={10} boxShadow="xl" direction="column">
                        <Flex direction="column" align="flex-start" justify="space-between">
                            <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={20} letterSpacing={2} pl={4} mb={3}><b>Edição:</b> {data.edition_year}</Heading>
                            <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={20} letterSpacing={2} pl={4}><b>Status Atual:</b> {data.active_stage}</Heading>
                        </Flex>
                        <Flex direction="row" align="flex-start" justify="flex-start" pl={4} mt={6}>
                            <Button
                                fontFamily={'Poppins'}
                                w={250}
                                fontSize={16}
                                letterSpacing={1}
                                bgGradient="linear-gradient(to right, #4da6a1, #38a459)"
                                color={'white'}
                                disabled={loading || data.active_stage != 'not_started'}
                                _hover={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                                onClick={() => updateChallengeEdition('registration_open')}
                            >
                                Abrir Inscrições
                            </Button>
                            <Button
                                fontFamily={'Poppins'}
                                w={250}
                                fontSize={16}
                                letterSpacing={1}
                                color={'white'}
                                ml={4}
                                disabled={loading || data.active_stage != 'registration_open'}
                                onClick={() => updateChallengeEdition('registration_closed')}
                                bgGradient="linear-gradient(to right, #616772, #d54040)"
                                _hover={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                            >
                                Encerrar inscrições
                            </Button>
                        </Flex>
                        <Flex direction="row" align="flex-start" justify="flex-start" pl={4} mt={6}>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                fontSize={16}
                                letterSpacing={1}
                                bgGradient="linear-gradient(to right, #4da6a1, #38a459)"
                                color={'white'}
                                disabled={loading || data.active_stage != 'registration_closed'}
                                onClick={() => updateChallengeEdition('1-submit_open')}
                                _hover={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                            >
                                Iniciar Primeira Fase
                            </Button>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                ml={4}
                                fontSize={16}
                                letterSpacing={1}
                                bgGradient="linear-gradient(to right, #627494, #3d4759)"
                                color={'white'}
                                disabled={loading || data.active_stage != '1-submit_open'}
                                onClick={() => updateChallengeEdition('1-evaluation_open')}
                                _hover={{
                                    bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                    boxShadow: 'xl',
                                }}
                            >
                                Enviar para Avaliadores
                            </Button>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                ml={4}
                                fontSize={16}
                                letterSpacing={1}
                                color={'white'}
                                disabled={ldn || disable1 || data.active_stage != '1-evaluation_open'}
                                onClick={() => endFirstStage()}
                                bgGradient="linear-gradient(to right, #616772, #d54040)"
                                _hover={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                            >
                                Encerrar Primeira Fase
                            </Button>
                        </Flex>
                        <Flex direction="row" align="flex-start" justify="flex-start" pl={4} mt={6}>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                fontSize={16}
                                letterSpacing={1}
                                bgGradient="linear-gradient(to right, #4da6a1, #38a459)"
                                color={'white'}
                                disabled={loading || data.active_stage != '1-stage_ended'}
                                onClick={() => updateChallengeEdition('2-mentoring_started')}
                                _hover={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                                    boxShadow: 'xl',
                                }}
                            >
                                Iniciar Segunda Etapa
                            </Button>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                ml={4}
                                fontSize={16}
                                letterSpacing={1}
                                bgGradient="linear-gradient(to right, #627494, #3d4759)"
                                color={'white'}
                                disabled={loading || data.active_stage != '2-mentoring_started'}
                                onClick={() => updateChallengeEdition('2-evaluation_open')}
                                _hover={{
                                    bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: "linear-gradient(to right, #627494, #3d4759)",
                                    boxShadow: 'xl',
                                }}
                            >
                                Enviar para Avaliadores
                            </Button>
                            <Button 
                                fontFamily={'Poppins'}
                                w={250}
                                ml={4}
                                fontSize={16}
                                letterSpacing={1}
                                color={'white'}
                                disabled={ldn2 || disable2 || data.active_stage != '2-evaluation_open'}
                                onClick={() => endSecondStage()}
                                bgGradient="linear-gradient(to right, #616772, #d54040)"
                                _hover={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                                _active={{
                                    bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                                    boxShadow: 'xl',
                                }}
                            >
                                Encerrar Segunda Fase
                            </Button>
                        </Flex>
                </Flex>
            </>


            }

            <FirstStageTable rows={rows1} columns={columns1} title="Primeira Etapa"/>
            <SecondStageTable rows={rows2} columns={columns2} title="Segunda Etapa" />

        </Flex>
    );

}


function FirstStageTable({ rows, columns }) {

    return(
        <Flex direction='column' minH='500px' w='100%' align='center'>
            <Flex w='90%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={1} pl={4} mt={10}>
                <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Primeira Etapa</Heading>
            </Flex>
            <DataGrid style={{width:'90%', marginTop:40, backgroundColor:'#ffffff8c', padding:'20px', borderRadius:10}} rows={rows} columns={columns} pageSize={20} rowHeight={40} components={{
                Toolbar: GridToolbar,
            }}  />
        </Flex>
    );

}


function SecondStageTable({ rows, columns, title }) {

    return(
        <Flex direction='column' minH='500px' w='100%' align='center'>
            <Flex w='90%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={1} pl={4} mt={10}>
                <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>{title}</Heading>
            </Flex>
            <DataGrid style={{width:'90%', marginTop:40, backgroundColor:'#ffffff8c', padding:'20px', borderRadius:10}} rows={rows} columns={columns} pageSize={20} rowHeight={40} components={{
                Toolbar: GridToolbar,
            }}  />
        </Flex>
    );

}
