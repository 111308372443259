import { Stack, Heading, Flex, Box, Button, Text } from '@chakra-ui/react'
import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { Loading, InputField } from '../components'
import { useHandleFormChange, useHandleFormSubmit, useFetch, useConfigureAmplify } from '../hooks';
import styles from '../styles/HomePage.module.css'


export default function Job() {

    useConfigureAmplify(0)

    let { job_id } = useParams();
    let [ status, data ] = useFetch("JOBS", '/job/' + job_id);

    return (
        <div className={styles.root}>
            {status === 'fetching' || data.length == 0 ? <Loading w='100%' h='100vh' /> : <JobPage data={data} job_id={job_id} />}
        </div>
    );

}


function JobPage({ data, job_id }) {

    return(
        <Flex minH='100vh' maxW='100%' p={0} position='relative' bgColor='secondary.main' align='center' justify='center' direction={['column', 'column', 'row']}>
            <JobDetails data={data} />
            <JobForm job_id={job_id} />
        </Flex>
    );

}


// Job details and job description
function JobDetails({ data }) {
    
    return(
        <Stack direction='column' w={['100%', '100%', '65%']} minH={['auto', 'auto', '100vh']} p={[5, 10, 10]} justify='center' align='flex-start' bgColor='secondary.main'>
            <Heading textAlign='start' fontWeight={500} fontSize={[18, 26, 30]} color='#dbdbdb' fontFamily='Poppins' borderLeft='7px solid #d5a741' pl={4} letterSpacing={1}>
                {data.job_title}
            </Heading>
            <Heading textAlign='start' fontWeight={400} fontSize={[15, 20, 20]} color='primary.main' fontFamily='Poppins' pl={6} letterSpacing={1} pt={4}>
                {data.area}
            </Heading>
            <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={12}>
                <span style={{fontWeight:500}}>Tipo da vaga: </span> {data.job_type}
            </Heading>
            <Heading textAlign='start' lineHeight={'25px'} fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={3}>
                <span style={{fontWeight:500}}>Descrição da vaga: </span> {data.job_description}
            </Heading>
            <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={3}>
                <span style={{fontWeight:500}}>Atividades: </span>
            </Heading>
            {data.job_responsibilities.map((item, index) => (
                <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={10} letterSpacing={1} pt={2} key={index}>
                    - {item}
                </Heading>
            ))}
            <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={3}>
                <span style={{fontWeight:500}}>Requisitos: </span>
            </Heading>
            {data.job_requirements.map((item, index) => (
                <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={10} letterSpacing={1} pt={2} key={index}>
                    - {item}
                </Heading>
            ))}
            <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={3}>
                <span style={{fontWeight:500}}>Benefícios: </span>
            </Heading>
            {data.job_benefits.map((item, index) => (
                <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={10} letterSpacing={1} pt={2} key={index}>
                    - {item}
                </Heading>
            ))}
            <Heading textAlign='start' fontWeight={300} fontSize={[12, 14, 16]} color='#dbdbdb' fontFamily='Poppins' pl={6} letterSpacing={1} pt={3} pb={5}>
                <span style={{fontWeight:500}}>Localização: </span> {data.job_location}
            </Heading>
        </Stack>
    );
}

// Job form
function JobForm({ job_id }) {

    const navigate = useNavigate();

    const [values, handleChange] = useHandleFormChange({
        name: '',
        email: '',
        phone: '',
        linkedin: '',
        birth_date: '',
        university: '',
        course: '',
        graduation_year: '',
        file_name: '',
        file: ''
    })

    const [err, submit] = useHandleFormSubmit({
        name: false,
        email: false,
        phone: false,
        linkedin: false,
        birth_date: false,
        university: false,
        course: false,
        graduation_year: false,
        file_name: false
    })

    const [isLoading, setIsLoading] = useState(false)


    return(
        <Stack direction='column' w={['100%', '100%', '35%']}  minH={['auto', 'auto', '100vh']} p={10} justify='center' align='center' bgColor='#ebdfc1'>
            <img src='/logo.png' alt='logo' width='60px'/>
            <Heading textAlign='center' fontWeight={600} fontSize={[20, 30, 30]} color='secondary.main' fontFamily='Poppins' letterSpacing={1} pb={10} pt={5}>
                Aplicar para vaga
            </Heading>
            <Box as={'form'} mt={10}>
                <Stack spacing={4}>
                    <InputField
                        placeholder="Nome Completo"
                        bg={'#dfd4b7'}
                        name="name"
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.name}</Text>
                    <InputField
                        placeholder="E-mail"
                        bg={'#dfd4b7'}
                        name="email"
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.email}</Text>
                    <InputField
                        placeholder="Telefone Celular"
                        bg={'#dfd4b7'}
                        name="phone"
                        onChange={handleChange}
                        type="phone"
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.phone}</Text>
                    <InputField
                        placeholder="Linkedin"
                        bg={'#dfd4b7'}
                        name="linkedin"
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.linkedin}</Text>
                    <InputField
                        placeholder="Data de Nascimento - dd/mm/aaaa"
                        bg={'#dfd4b7'}
                        name="birth_date"
                        onChange={handleChange}
                        type="text"
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.birth_date}</Text>
                    <Flex justify='space-between'>
                        <Flex w="48%" direction="column">
                            <InputField
                                placeholder="Universidade"
                                bg={'#dfd4b7'}
                                border={0}
                                color={'gray.500'}
                                _placeholder={{
                                    fontSize:12,
                                    color: 'gray.500',
                                    fontFamily: 'Poppins'
                                }}
                                w='100%'
                                name="university"
                                onChange={handleChange}
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.university}</Text>
                        </Flex>
                        <Flex w='48%' direction="column">
                            <InputField
                                placeholder="Curso"
                                bg={'#dfd4b7'}
                                border={0}
                                color={'gray.500'}
                                _placeholder={{
                                    fontSize:12,
                                    color: 'gray.500',
                                    fontFamily: 'Poppins'
                                }}
                                w='100%'
                                name="course"
                                onChange={handleChange}
                            />
                            <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.course}</Text>
                        </Flex>
                    </Flex>
                    <InputField
                        placeholder="Ano / previsão de graduação - mm/aa"
                        bg={'#dfd4b7'}
                        name="graduation_year"
                        onChange={handleChange}
                    />
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.graduation_year}</Text>
                    <label style={{ textAlign:'center', fontWeight:500, backgroundColor:'#efe9da', color:'#222f46', borderRadius:5, padding:'10px 10px 10px 15px', cursor:'pointer', fontSize:12, fontFamily:'Poppins'}}>
                        <input
                            type="file"
                            hidden
                            name="file"
                            onChange={handleChange}
                        />
                        Upload CV
                    </label>
                    <Text style={{marginTop:0, fontSize:10, textAlign:'center', marginTop:3, color:'#222f46'}}>
                        {values.file_name}
                    </Text>
                    <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.file_name}</Text>
                    
                </Stack>
                    <Button
                    fontFamily={'heading'}
                    mt={8}
                    w={'full'}
                    bgGradient="linear(to-r, secondary.main,primary.main)"
                    color={'white'}
                    _hover={{
                        bgGradient: 'linear(to-r, secondary.main, primary.main)',
                        boxShadow: 'xl',
                    }}
                    onClick={() => submit(values, setIsLoading, navigate, job_id)}
                    isLoading={isLoading}
                    loadingText='Enviando'
                >
                        Enviar
                </Button>
                <Text color="#222f469c" fontSize={12} textAlign='center' style={{marginTop:5}}>Ao enviar voce concorda com nossas <a href='/PoliticadePrivacidade.pdf' target='_blank'>políticas de privacidade</a></Text>
            </Box>
        </Stack>
    );
}