import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


// Custom hook to fetch data from the API
// @param {string} endpoint - the path to the API endpoint
const useUpdateChallengeEdition = () => {

    let [loading, setLoading] = useState(false);
    const [status, setStatus] = useState('idle');
    const [data, setData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                const result = await API.get("CHALLENGE_EDITION", '/edition/2022', myInit);
                console.log(result)
                setData(result);
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.resonse.status == 404) {
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();
        
    }, []);

    const updateEdition = async (newStage) => {

        setLoading(true);

        try {
            let myInit = { 
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body: {
                    edition_year: 2022,
                    update: {
                        active_stage: newStage
                    }
                }
            }
            const result = await API.post("CHALLENGE_EDITION", '/edition/update', myInit);
            setLoading(false);
            return window.location.reload()
        } catch (error) {
            console.log(error)
            setStatus('idle');
            alert('Erro ao atualizar dados, tente novamente mais tarde.');
        }
    };
        
    
    return [ status, data, loading, updateEdition ];
};

export default useUpdateChallengeEdition;