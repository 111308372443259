import { Container, Stack, Heading, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import { GrFormNextLink } from 'react-icons/gr'
import { useNavigate } from 'react-router-dom';
import Amplify from 'aws-amplify';

import { useConfigureAmplify, useFetch } from '../hooks';
import { Footer, Loading } from '../components';
import styles from '../styles/HomePage.module.css'


export default function Opportunities() {

    useConfigureAmplify(0)

    let [ status, data ] = useFetch("JOBS", '/jobs/active');
    let navigate = useNavigate();

    return (
        <div className={styles.root}>
            <Header />
            {status === 'fetching' ? <Loading h='60vh' w='100%' /> : <Jobs data={data} navigate={navigate} />}
            <Footer />
        </div>
    );

}


// First section: headers and titles
function Header() {
    
    return(

        <Container minH='18vh' maxW='100%' position='relative' p={0}>

            {/* Main title and subtitle */}
            <Stack direction='column' w='100%' h='18vh' p={10} justify='flex-start' align='center' bgColor='secondary.main'>
                <Heading textAlign='center' fontWeight={500} fontSize={[40, 60, 60]} color='#dbdbdb' fontFamily='Poppins' >
                    Vagas Abertas
                </Heading>
            </Stack>

        </Container>

    );
}

// Second section: about the challenge
function Jobs({ data, navigate }) {
    
    return(
        <Container minH='60vh' maxW='100%' p={0} position='relative' bgColor='#d5a7402e'>
            <Stack w='100%' minH='60vh' align='center' justify='flex-start' direction={['column', 'column', 'column']} p={[5, 10, 15]}>
                { data && data.length > 0 ?
                    <SimpleGrid columns={[1,2,3]} spacing={6} mt={10}>
                        {data.map((item, index) => (
                            <JobCard key={item.job_id} title={item.job_title} area={item.area} type={item.job_type} redirectTo={() => navigate(`/vaga/${item.job_id}`)}/>
                        ))}
                    </SimpleGrid>
                : <Heading mt={100} textAlign='center' fontWeight={500} fontSize={[30, 30, 40]} color='secondary.main' fontFamily='Poppins' >Sem vagas abertas no momento :(</Heading>})
            </Stack>
        </Container>
    );
}  

// Opportunity card
function JobCard({title, area, type, Icon, redirectTo}) {

    return(
        <Flex onClick={() => redirectTo()} direction='column' maxW={['100%', '400px', '400px']} minH='150px' h={['auto', 'auto', 'auto']} align='flex-start' justify='flex-start' p={5} bgColor='#fffaeb' borderRadius={10} boxShadow='xl' cursor='pointer' position='relative' _hover={{boxShadow:'2xl'}}>
            <Heading fontSize={18} fontWeight={600} color='#d5a741' fontFamily='Poppins'>
                {title}
            </Heading>
            <Heading fontSize={16} fontWeight={500} mt={2} color='secondary.main' fontFamily='Poppins' lineHeight={6}>
                {area}
            </Heading>
            <Heading fontSize={12} fontWeight={300} mt={5} color='secondary.main' fontFamily='Poppins'>
                {type}
            </Heading>
            <Flex position='absolute' bottom={3} right={4} direction='row' align='center'>
                <Heading fontSize={12} fontWeight={500} color='secondary.main' fontFamily='Poppins' mt='2px'>
                    DETALHES
                </Heading>
                <GrFormNextLink size={20} color=''/>
            </Flex>
        </Flex>
    );

}