import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';


export default function Header({ title }) {

    return (
        <Flex w='100%' h='55px' bgColor='#222f46de' position='absolute' top={0} left={0} direction='column' alignItems='center' justifyContent='center' pl={250} pt={5} pb={5}>
            <Heading fontWeight={500} color='white' fontFamily='Poppins' fontSize={26} letterSpacing={2} >{title}</Heading>
        </Flex>
    );
}