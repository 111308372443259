import { Text, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


const useChallenge2ndStageHR = (stage) => {

    let [status, setStatus] = useState('');
    let [data, setData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [disable, setDisable] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                const result = await API.get("CHALLENGE_TEAMS", "/teams/all/" + `2022/approved_1`, myInit);
                result.length > 0 ? setDisable(disableEndStage(result)) : setDisable(true)
                setData(result);
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.response.status == 404) {
                    setDisable(true)
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [stage]);

    const changeTeamStageStatus = async (team_login, status, second_stage) => {
        setLoading(true)
        let body = {
            login_email : team_login,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                second_stage : {...second_stage, status : status}
            }
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/update', myInit);
            setLoading(false)
            window.location.reload()
            alert('Time avaliado com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }

    const endStage = async (updateChallengeEdition) => {
        setLoading(true)
        let body = {
            ending_stage : "second_stage",
            ending_stage_number : 2
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/updateStatus', myInit);
            let myInit2 = { 
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body: {
                    edition_year: 2022,
                    update: {
                        active_stage: "2-stage_ended"
                    }
                }
            }
            const result = await API.post("CHALLENGE_EDITION", '/edition/update', myInit2);
            setLoading(false)
            window.location.reload()
            alert('Etapa encerrada com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }
                        
    var rows2 = []
    var columns2 = []

    if (data) {
        [ rows2, columns2 ] = prepareStageData(data, changeTeamStageStatus, loading);
    }

    return [ status, { rows2, columns2 }, disable, endStage, loading ];
        
};

function disableEndStage(teams) {
    console.log(teams)
    if (teams.length == 0) {
        console.log("entrou")
        return true
    }
    console.log("saiu")
    
    let disable = false
    for (let i = 0; i < teams.length; i++) {
        if (teams[i].second_stage && (!teams[i].second_stage.status || teams[i].second_stage.status == 'evaluated')) {
            disable = true
            break
        }
    }
    return disable
}

function prepareStageData(data, evaluate1stStage, loading) {
    let rows = []
    let columns = []
    
    for (let i = 0; i < data.length; i++) {
        console.log(data[i])
        rows.push({
            id: i,
            team_name : data[i].team_name,
            login_email : data[i].login_email,
            evaluator: data[i].evaluator,
            m1_university : data[i].members[0].university,
            m2_university : data[i].members[1].university,
            m3_university : data[i].members[2].university,
            m1_course : data[i].members[0].course,
            m2_course : data[i].members[1].course,
            m3_course : data[i].members[2].course,
            m1_semester : data[i].members[0].semester,
            m2_semester : data[i].members[1].semester,
            m3_semester : data[i].members[2].semester,
            im_avg_score: data[i].second_stage ? getAvgScore(data[i].second_stage, "im") : 0,
            valuation_avg_score: data[i].second_stage ? getAvgScore(data[i].second_stage, "valuation") : 0,
            status : data[i].status,
            stage_status : data[i].second_stage && data[i].second_stage.im_link && data[i].second_stage.valuation_link && !data[i].second_stage.status ? "submitted" : data[i].second_stage && data[i].second_stage.status ? data[i].second_stage.status : '',
            second_stage: data[i].second_stage ? data[i].second_stage : {}
        })
    }

    columns = [
        { field: 'team_name', headerName: 'Equipe', width: 200 },
        { field: 'stage_status', headerName: 'Status da Etapa', width: 200,
            renderCell: (params) => {
                if (params.value == "evaluated") {
                    return <Badge color="secondary.main" label="Avaliado"/>
                } else if (params.value == "submitted") {
                    return <Badge color="primary.main" label="Enviado"/>
                } else if (params.value == "approved") {
                    return <Badge color="#60ac5a" label="Aprovado"/>
                } else if (params.value == "disapproved") {
                    return <Badge color="#b33535" label="Reprovado"/>
                } else if (params.value == "not_submitted") {
                    return <Badge color="#b33535" label="Não Enviado"/>
                }
            }
        },
        { field: 'evaluator', headerName: 'Avaliador', width: 230 },
        // { field: 'status', headerName: 'Status Geral', width: 200 },
        { field: 'm1_university', headerName: 'Universidade M1', width: 200 },
        { field: 'm2_university', headerName: 'Universidade M2', width: 200 },
        { field: 'm3_university', headerName: 'Universidade M3', width: 200 },
        { field: 'm1_course', headerName: 'Curso M1', width: 200 },
        { field: 'm2_course', headerName: 'Curso M2', width: 200 },
        { field: 'm3_course', headerName: 'Curso M3', width: 200 },
        { field: 'm1_semester', headerName: 'Semestre M1', width: 200 },
        { field: 'm2_semester', headerName: 'Semestre M2', width: 200 },
        { field: 'm3_semester', headerName: 'Semestre M3', width: 200 },
        { field: 'im_avg_score', headerName: 'Pontuação Média IM', width: 200 },
        { field: 'valuation_avg_score', headerName: 'Pontuação Média Valuation', width: 200 },
        { field: 'approve', width: 150, headerName: 'Aprovar', width: 160,
            renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        fontSize={11}
                        h={"30px"}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #4da6a1, #38a459)"
                        color={'white'}
                        _hover={{
                            bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                            boxShadow: 'xl',
                        }}
                        disabled={loading || params.row.stage_status == "submitted" || params.row.stage_status == "not_submitted"}
                        onClick={() => evaluate1stStage(params.row.login_email, "approved", params.row.second_stage)}
                    >
                        APROVAR
                    </Button>
                    
                );
            }
        },
        { field: 'disapprove', width: 150, headerName: 'Reprovar', width: 160,
            renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        fontSize={11}
                        h={"30px"}
                        letterSpacing={1}
                        color={'white'}
                        bgGradient="linear-gradient(to right, #616772, #d54040)"
                        _hover={{
                            bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                            boxShadow: 'xl',
                        }}
                        disabled={loading || params.row.stage_status == "submitted" || params.row.stage_status == "not_submitted"}
                        onClick={() => evaluate1stStage(params.row.login_email, "disapproved", params.row.second_stage)}
                    >
                        REPROVAR
                    </Button>
                    
                );
            }
        }
    ];

    return [rows, columns]
}

function getAvgScore(data, prefix) {

    let avg = 0;
    avg = (parseInt(data[prefix + '_clarity']) + parseInt(data[prefix + '_criativity']) + parseInt(data[prefix + '_extra_information']) + parseInt(data[prefix + '_logical_fundamentals']) + parseInt(data[prefix + '_message']) + parseInt(data[prefix + '_participation']) + parseInt(data[prefix + '_salesmanship']) + parseInt(data[prefix + '_technique']) + parseInt(data[prefix + '_visual'])) / 9;
    return Math.round(avg * 100) / 100
}

function Badge({color, label}) {
    return <Text bgColor={color} fontFamily='Poppins' fontSize={11} textTransform="uppercase" w={100} textAlign="center" fontWeight={600} lineHeight={"30px"} borderRadius={5} h={"30px"} color="white">{label}</Text>
}

export default useChallenge2ndStageHR;