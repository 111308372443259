import { useState } from 'react';


// Custom hook that handles form changes
const useHandleArrayInput = (initialState) => {
    
    const [values, setValues] = useState(initialState);

    const handleChange = (e, index) => {
        const { value } = e.target;
        
        let newArray = [...values];
        newArray[index] = value;
        setValues(newArray);
        
    }

    return [values, setValues, handleChange];    

}

export default useHandleArrayInput;