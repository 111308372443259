import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


// Custom hook to fetch data from the API
// @param {string} endpoint - the path to the API endpoint
const useUpdateJob = () => {

    const [status, setStatus] = useState('idle');


    const updateJob = async (body) => {
        
        setStatus('updating');
        try {
            let myInit = { 
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body: body
            }
            const result = await API.post("JOBS", 'job/update', myInit);
            setStatus('idle');
            return window.location.reload()
        } catch (error) {
            console.log(error)
            setStatus('idle');
            alert('Erro ao atualizar dados, tente novamente mais tarde.');
        }
    };
        
    
    return [ status, updateJob ];
};

export default useUpdateJob;