import { useState } from 'react';
import { Auth } from 'aws-amplify';


// Custom hook that handles form submit
const useHandleLogin = (initialState) => {
    
    const [err, setErr] = useState({...initialState});

    const login = async (values, setIsLoading, navigate) => {

        setIsLoading(true)

        // Validate form
        let newErr = {...initialState};
        let hasErr = false;

        let domain = values.email && values.email.split('@').length > 1 ? values.email.split('@')[1] != 'igcp.com.br' : true;

        if (values.email.length == 0 || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email) || domain) {
            newErr.email = 'Email inválido';
            hasErr = true;
        }
        if (values.password.length == 0) {
            newErr.password = 'Insira sua senha';
            hasErr = true;
        }

        // If there are errors, set the error state
        if (hasErr) {
            setIsLoading(false)
            return setErr({...newErr});
        } else {
            setErr({...initialState});
        }

        try {
            const user = await Auth.signIn(values.email, values.password);
            if (user.attributes.email != process.env.REACT_APP_HR_EMAIL) {
                navigate('/evaluatechallenge#firststage');
            } else {
                navigate('/managejobs');
            }
        } catch (err) {
            setIsLoading(false)
            setErr({...newErr, password: err.message})
        }
        
    }

    return [err, login];    

}

export default useHandleLogin;