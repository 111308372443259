

import { Heading, Flex } from '@chakra-ui/react'


export default function NotFound() {

    return(
        <Flex minH='100vh' maxW='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='center' direction={'column'}>
            <img src='/logo.png' alt='logo' width='60px' style={{marginBottom:40}}/>
            <Heading textAlign='center' >Ops, essa página não existe :(</Heading>
        </Flex>
    );

}