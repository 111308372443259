import { Stack, Flex, Box, Button, Text } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { useHandleFormChange, useHandleLogin, useConfigureAmplify } from '../hooks'
import { InputField } from '../components'
import styles from '../styles/HomePage.module.css'


export default function Login() {

    useConfigureAmplify(1)

    return (
        <div className={styles.root}>
            <LoginComponent />
        </div>
    );

}


function LoginComponent({ data, job_id }) {

    const [values, handleChange] = useHandleFormChange({
        email: '',
        password: ''
    })

    const [err, login] = useHandleLogin({
        email: false,
        password: false
    })

    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    return(
        <Flex minH='100vh' maxW='100%' p={0} position='relative' bgColor='#efe8d8' align='center' justify='center' direction={'row'}>
            <Stack direction='column' w={['90%', '80%', '35%']}  minH='auto' p={10} justify='center' align='center' bgColor='#ebdfc1' borderRadius={20} boxShadow='xl'>
                <img src='/logo.png' alt='logo' width='60px' style={{marginBottom:40}}/>
                <Box as={'form'} mt={10} minW='80%'>
                    <Stack spacing={4}>
                        <InputField
                            placeholder="email"
                            name="email"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.email}</Text>
                        <InputField
                            placeholder="password"
                            type="password"
                            name="password"
                            disabled={isLoading}
                            onChange={handleChange}
                        />
                        <Text color="error.main" fontSize={12} style={{marginTop:2, marginLeft:12}}>{err.password}</Text>
                    </Stack>
                    <Button
                        fontFamily={'heading'}
                        mt={8}
                        w={'full'}
                        bgGradient="linear(to-r, secondary.main,primary.main)"
                        color={'white'}
                        _hover={{
                            bgGradient: 'linear(to-r, secondary.main, primary.main)',
                            boxShadow: 'xl',
                        }}
                        onClick={() => login(values, setIsLoading, navigate)}
                        isLoading={isLoading}
                        loadingText='Enviando'
                        type='submit'
                    >
                        Enviar
                    </Button>
                </Box>
            </Stack>
        </Flex>
    );

}