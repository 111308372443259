import { useEffect } from 'react';
import Amplify from 'aws-amplify';


// Custom hook to configure Amplify
// This is a custom hook that handles the configuration of Amplify
// @param {int} type - type of user (0 - applicant; 1 - HR)
const useConfigureAmplify = (type) => {

    useEffect(() => {
        
        let region =                     process.env.REACT_APP_REGION
        let apiJobsUrl =                (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_API_JOBS_DEV : process.env.REACT_APP_API_JOBS_PROD)
        let apiApplicantsUrl =          (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_API_APPLICANTS_DEV : process.env.REACT_APP_API_APPLICANTS_PROD)
        let apiChallengeEditionUrl =    process.env.REACT_APP_API_CHALLENGE_EDITION_DEV
        let apiChallengeTeamsUrl =      process.env.REACT_APP_API_CHALLENGE_TEAMS_DEV
        let apiChallengeSchedulerUrl =  process.env.REACT_APP_API_CHALLENGE_SCHEDULER_DEV
        let identityPoolId =            (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_IDENTITY_POOL_ID_DEV : process.env.REACT_APP_IDENTITY_POOL_ID_PROD)
        let identityPoolIdHR =          process.env.REACT_APP_IDENTITYPOOL_HR
        let bucket =                    (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_RECRUITMENT_S3_BUCKET_DEV : process.env.REACT_APP_RECRUITMENT_S3_BUCKET_PROD)
        let userPoolIdAp =              (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_USERPOOLID_DEV : process.env.REACT_APP_USERPOOLID_PROD)
        let clientIdAp =                (process.env.REACT_APP_ENV == 'dev' ? process.env.REACT_APP_COGNITO_CLIENTID_DEV : process.env.REACT_APP_COGNITO_CLIENTID_PROD)
        let userPoolIdHR =              process.env.REACT_APP_HR_USERPOOL_ID
        let clientId =                  process.env.REACT_APP_HR_CLIENT_ID

        let storage = {
            AWSS3: {
                bucket: bucket, 
                region: region, 
            }
        }

        let api = {
            endpoints: [
                {
                    name: "JOBS",
                    endpoint: apiJobsUrl
                },
                {
                    name: "APPLICANTS",
                    endpoint: apiApplicantsUrl
                },
                {
                    name: "CHALLENGE_EDITION",
                    endpoint: apiChallengeEditionUrl
                },
                {
                    name: "CHALLENGE_TEAMS",
                    endpoint: apiChallengeTeamsUrl
                },
                {
                    name: "CHALLENGE_SCHEDULER",
                    endpoint: apiChallengeSchedulerUrl
                },
            ]
        }

        // General user / applicant configuration
        if (type == 0) {

            Amplify.configure({
                Auth: {
                    region: region,
                    userPoolId: userPoolIdAp,
                    userPoolWebClientId: clientIdAp,
                    identityPoolId: identityPoolId
                },
                Storage: storage,
                API: api
            });
                
        // HR user configuration
        } else if (type == 1) {
            
            Amplify.configure({
                Auth: {
                    region: region,
                    userPoolId: userPoolIdHR,
                    userPoolWebClientId: clientId,
                    identityPoolId: identityPoolIdHR,
                    mandatorySignIn: true
                },
                Storage: storage,
                API: api
            });
        }
    
        
    }, []);
    
    return true;
};

export default useConfigureAmplify;