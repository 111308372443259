import React, { useEffect, useState } from 'react'
import Amplify, { Auth } from 'aws-amplify';
import { Flex, Spinner } from '@chakra-ui/react'
import { Navigate } from 'react-router-dom'

import { useConfigureAmplify } from '../../hooks'
import { Loading } from '../../components'

export default function PrivateRoute({ children, path }) {

    useConfigureAmplify(1)

    const [data, setData] = useState({ auth : 'waiting' });

    useEffect(async () => {
        try {
            var user = await Auth.currentAuthenticatedUser()
            if (user.attributes.email == process.env.REACT_APP_HR_EMAIL || path == "/evaluatechallenge") {
                setData({ auth : 'Authorized' })
            } else {
                setData({ auth : 'Unauthorized' })
            }
        } catch(e) {
            setData({ auth : 'Unauthorized' })
        }
    }, []);

    if (data.auth == 'waiting') {
        return (
            <Loading h='100vh' w='100%' />
        )
    } else if (data.auth == 'Unauthorized') {
        return <Navigate to='/' />
    } else if (data.auth == 'Authorized') {
        return children
    }
    return (
        <Loading h='100vh' w='100%' />
    )
}
