import React from 'react';
import { Flex, Divider } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { VscDebugStart } from 'react-icons/vsc'
import { IoIosPeople } from 'react-icons/io';
import { MdFestival } from 'react-icons/md';
import { RiLogoutBoxRLine } from 'react-icons/ri';

import logo from '../../assets/white_logo.png';

export default function SideBar() {

    let navigate = useNavigate();
    let location = useLocation();
    let path = location.hash;
  
    return (
        <Flex w='250px' h='100%' bgColor='secondary.main' position='absolute' top={0} left={0} direction='column' alignItems='center' p={5} pt={10} zIndex={100}>
            <img src={logo} alt='logo' width='60px' height='auto' />
            <Divider mt={10} />

            <MenuButton Icon={VscDebugStart} active={path == '#firststage'} name='Primeira Etapa' onclick={() => navigate('/evaluatechallenge#firststage')} />
            <MenuButton Icon={IoIosPeople} active={path == '#mentoring'} name='Mentoria' onclick={() => navigate('/evaluatechallenge#mentoring')} />
            <MenuButton Icon={MdFestival} active={path == '#secondstage'} name='Segunda Etapa' onclick={() => navigate('/evaluatechallenge#secondstage')} />

            <Divider mt={10} />

            <MenuButton name='Sair' 
                Icon={RiLogoutBoxRLine}
                onclick={
                    () => {
                        Auth.signOut()
                        .then(data => {
                            navigate('/')
                        })
                        .catch(err => console.log(err))
                    }
                } 
            />

        </Flex>
    );
}

function MenuButton({ name, onclick, Icon, active }) {

    return(
        <Flex
            h="45px"
            fontFamily={'Poppins'}
            align="center"
            borderRadius={5}
            pl={5}
            mt={4}
            w={"100%"}
            color="white"
            bgGradient={active ? "linear-gradient(to right, #616772, #3a588d)" : "transparent"}
            textAlign={'left'}
            cursor={'pointer'}
            _hover={{
                bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
            }}
            onClick={() => onclick()}
        >
            {Icon && <Icon size={22} style={{marginRight:15}} />}
            {name}
        </Flex>
    );
    
}