import { ChakraProvider } from '@chakra-ui/react'
import Amplify from 'aws-amplify';

import theme from './styles/theme'
import Routes from './routes';


function App() {

    return (
        <ChakraProvider theme={theme}>
            <Routes />
        </ChakraProvider>
    )
}

export default App