import { BsFillLightningChargeFill, BsFillSunFill, BsFillBriefcaseFill } from 'react-icons/bs'
import { Container, Stack, Heading, Flex } from '@chakra-ui/react'
import { useNavigate } from "react-router-dom";

import { Footer } from '../components'
import styles from '../styles/HomePage.module.css'
import officeBg from '../assets/office.jpg'


export default function Homepage() {

    let navigate = useNavigate();

    return (
        <div className={styles.root}>
            <FirstSection />
            <SecondSection navigate={navigate} />
            <Footer />
        </div>
    );

}


// First section: headers and titles
function FirstSection() {
    
    return(

        <Container minH='35vh' maxW='100%' position='relative' bgImage={officeBg} bgSize='cover' p={0}>

            {/* Main title and subtitle */}
            <Stack direction='column' w='100%' h='35vh' p={10} justify='flex-start' align='center' bg='linear-gradient(174deg, rgba(34,47,70,1) 15%, rgb(213 167 65 / 48%) 100%)'>
                <Heading textAlign='center' fontWeight={500} fontSize={70} color='#dbdbdb' fontFamily='Poppins' mt={50}>
                    Trabalhe na <span style={{color:"#d5a741"}}>igc</span>
                </Heading>
            </Stack>

        </Container>

    );
}

// Second section: about the challenge
function SecondSection({ navigate }) {

    return(
        <Container minH='65vh' maxW='100%' p={0} position='relative' bgColor='#d5a7402e'>
            <Stack w='100%' minH='65vh' align='center' justify='space-evenly' direction={['column', 'column', 'column']} p={[5, 10, 15]}>
                <Heading textAlign='center' fontSize={[16, 24, 24]} lineHeight={[8, 10, 10]} fontWeight={400} p={5} color='secondary.main' fontFamily='Poppins' w={'90%'}>
                    O capital humano é um dos pilares que move a igc. Aqui, quem faz a diferença é você. Oferecemos um ambiente colaborativo, composto de pessoas que partilham dos mesmos princípios: respeito, gentileza e honestidade.
                </Heading>
                <Stack direction={['column', 'column', 'row']} w={['100%', '95%', '90%']} h='100%' align={['center', 'center', 'flex-start']} justify='space-evenly' >
                    <Card
                        title='igc challenge'
                        subtitle='A maior competição de M&A do Brasil para estudantes universitários.'
                        description='Todos os participantes da etapa final poderão receber uma proposta de trabalho da igc Partners'
                        Icon={BsFillLightningChargeFill}
                        redirectTo={() => window.open('https://challenge.igcp.com.br', '_blank')}
                    />
                    <Card
                        title='igc summer internship'
                        subtitle='3 meses de imersão no mundo do M&A com os melhores profissionais do mercado.'
                        description='Para estudantes brasileiros que cursam graduação no exterior'
                        Icon={BsFillSunFill}
                        redirectTo={() => alert('Mais informações em breve')}
                    />
                    <Card
                        title='outras oportunidades'
                        subtitle='Vagas para Áreas de Negócios e Suporte'
                        description='Envie seu curriculo pra gente :)'
                        Icon={BsFillBriefcaseFill}
                        redirectTo={() => navigate('/vagas')}
                    />
                </Stack>
            </Stack>
        </Container>
    );
}  

// Opportunity card
function Card({ title, subtitle, description, Icon, redirectTo }) {

    return(
        <Flex onClick={() => redirectTo()} direction='column' w={['90%', '90%', '30%']} minH='200px' h={['auto', 'auto', 'auto']} style={{marginBottom:10}} align='flex-start' justify='flex-start' p={5} bgColor='#fdf6e1' borderRadius={10} boxShadow='xl' cursor='pointer' position='relative' _hover={{boxShadow:'2xl'}}>
            {Icon ? <Icon style={{position:'absolute', top:25, right:20, color:'#d5a741'}}/> : null}
            <Heading fontSize={22} fontWeight={600} color='#d5a741' fontFamily='Poppins'>
                {title}
            </Heading>
            <Heading fontSize={16} fontWeight={300} mt={5} color='secondary.main' fontFamily='Poppins' lineHeight={6}>
                {subtitle}
            </Heading>
            <Heading fontSize={12} fontWeight={300} mt={5} color='secondary.main' fontFamily='Poppins'>
                {description}
            </Heading>
        </Flex>
    );

}