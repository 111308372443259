

import { Text, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { API, Auth } from 'aws-amplify';


const useChallenge1stStageHR = (stage) => {

    let [status, setStatus] = useState('');
    let [data, setData] = useState([]);
    let [loading, setLoading] = useState(false);
    let [disable, setDisable] = useState(true);

    useEffect(() => {

        const fetchData = async () => {
            setStatus('fetching');
            try {
                let myInit = {}
                try {
                    myInit = { 
                        headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                    }
                } catch (error) {
                    console.log(error)
                }
                var user = await Auth.currentAuthenticatedUser()
                const result = await API.get("CHALLENGE_TEAMS", "/teams/all/" + `2022/registered`, myInit);
                result.length > 0 ? setDisable(disableEndFirstStage(result)) : setDisable(true)
                setData(result);
                setStatus('idle');
            } catch (error) {
                console.log(error)
                setStatus('idle');
                if (error.response.status == 404) {
                    setDisable(true)
                    setData([]);
                } else {
                    alert('Erro ao carregar os dados, tente novamente mais tarde.');
                }
            }
        };

        fetchData();

    }, [stage]);

    const changeTeamStageStatus = async (team_login, status, first_stage) => {
        setLoading(true)
        let body = {
            login_email : team_login,
            challenge_year : 2022, //TODO: CHANGE HARDCODED
            update : {
                first_stage : {...first_stage, status : status}
            }
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/update', myInit);
            setLoading(false)
            window.location.reload()
            alert('Time avaliado com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }

    const endFirstStage = async (updateChallengeEdition) => {
        setLoading(true)
        let body = {
            ending_stage : "first_stage",
            ending_stage_number : 1
        }
        try {
            let user = await Auth.currentAuthenticatedUser();
            let myInit = { 
                headers : { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body : body
            }
            let response = await API.post('CHALLENGE_TEAMS', '/teams/updateStatus', myInit);
            let myInit2 = { 
                headers: { Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}` },
                body: {
                    edition_year: 2022,
                    update: {
                        active_stage: "1-stage_ended"
                    }
                }
            }
            const result = await API.post("CHALLENGE_EDITION", '/edition/update', myInit2);
            setLoading(false)
            window.location.reload()
            alert('Etapa encerrada com sucesso.')
        } catch (err) {
            console.log(err)
            alert("Erro, tente novamente mais tarde ou contate o administrador do sistema.")
            setLoading(false)
        }
    }
                        
    var rows1 = []
    var columns1 = []

    if (data) {
        [ rows1, columns1 ] = prepare1stStageData(data, changeTeamStageStatus, loading);
    }

    return [ status, { rows1, columns1 }, disable, endFirstStage, loading ];
        
};

function disableEndFirstStage(teams) {

    if (teams.length == 0) {
        return true
    }

    let disable = false
    for (let i = 0; i < teams.length; i++) {
        if (!teams[i].first_stage) {
            continue
        }
        if (teams[i].first_stage.status == "submitted" || teams[i].first_stage.status == "evaluated") {
            console.log(teams[i])
            disable = true
            break
        }
    }
    return disable
}

function prepare1stStageData(data, evaluate1stStage, loading) {

    let rows = []
    let columns = []

    for (let i = 0; i < data.length; i++) {
        rows.push({
            id: i,
            team_name : data[i].team_name,
            login_email : data[i].login_email,
            evaluator: data[i].evaluator,
            m1_university : data[i].members[0].university,
            m2_university : data[i].members[1].university,
            m3_university : data[i].members[2].university,
            m1_course : data[i].members[0].course,
            m2_course : data[i].members[1].course,
            m3_course : data[i].members[2].course,
            m1_semester : data[i].members[0].semester,
            m2_semester : data[i].members[1].semester,
            m3_semester : data[i].members[2].semester,
            clarity : data[i].first_stage && data[i].first_stage.clarity ? data[i].first_stage.clarity : -1,
            criativity : data[i].first_stage && data[i].first_stage.criativity ? data[i].first_stage.criativity : -1,
            extra_information : data[i].first_stage && data[i].first_stage.extra_information ? data[i].first_stage.extra_information : -1,
            logical_fundamentals : data[i].first_stage && data[i].first_stage.logical_fundamentals ? data[i].first_stage.logical_fundamentals : -1,
            message : data[i].first_stage && data[i].first_stage.message ? data[i].first_stage.message : -1,
            participation : data[i].first_stage && data[i].first_stage.participation ? data[i].first_stage.participation : -1,
            salesmanship : data[i].first_stage && data[i].first_stage.salesmanship ? data[i].first_stage.salesmanship : -1,
            technique : data[i].first_stage && data[i].first_stage.technique ? data[i].first_stage.technique : -1,
            visual : data[i].first_stage && data[i].first_stage.visual ? data[i].first_stage.visual : -1,
            status : data[i].status,
            stage_status : data[i].first_stage && data[i].first_stage.status ? data[i].first_stage.status : "not_submitted",
            avg_score: data[i].first_stage ? getAvgScore(data[i].first_stage) : 0,
            first_stage: data[i].first_stage
        })
    }

    columns = [
        { field: 'team_name', headerName: 'Equipe', width: 200 },
        { field: 'stage_status', headerName: 'Status da Etapa', width: 200,
            renderCell: (params) => {
                if (params.value == "evaluated") {
                    return <Badge color="secondary.main" label="Avaliado"/>
                } else if (params.value == "submitted") {
                    return <Badge color="primary.main" label="Enviado"/>
                } else if (params.value == "not_submitted") {
                    return <Badge color="primary.main" label="Não Enviado"/>
                } else if (params.value == "approved") {
                    return <Badge color="#60ac5a" label="Aprovado"/>
                } else if (params.value == "disapproved") {
                    return <Badge color="#b33535" label="Reprovado"/>
                }
            }
        },
        { field: 'evaluator', headerName: 'Avaliador', width: 230 },
        // { field: 'status', headerName: 'Status Geral', width: 200 },
        { field: 'm1_university', headerName: 'Universidade M1', width: 200 },
        { field: 'm2_university', headerName: 'Universidade M2', width: 200 },
        { field: 'm3_university', headerName: 'Universidade M3', width: 200 },
        { field: 'm1_course', headerName: 'Curso M1', width: 200 },
        { field: 'm2_course', headerName: 'Curso M2', width: 200 },
        { field: 'm3_course', headerName: 'Curso M3', width: 200 },
        { field: 'm1_semester', headerName: 'Semestre M1', width: 200 },
        { field: 'm2_semester', headerName: 'Semestre M2', width: 200 },
        { field: 'm3_semester', headerName: 'Semestre M3', width: 200 },
        { field: 'avg_score', headerName: 'Pontuação Média', width: 200 },
        { field: 'clarity', hide: true, type: 'number', editable: true, headerName: 'Clareza', width: 130 },
        { field: 'criativity', hide: true, type: 'number', editable: true, headerName: 'Criatividade', width: 160 },
        { field: 'extra_information', hide: true, type: 'number', editable: true, headerName: 'Inf. Extras', width: 150 },
        { field: 'logical_fundamentals', hide: true, type: 'number', editable: true, headerName: 'Fund. Lógicos', width: 170 },
        { field: 'message', hide: true, type: 'number', editable: true, headerName: 'Mensagem', width: 150 },
        { field: 'participation', hide: true, type: 'number', editable: true, headerName: 'Participação', width: 160 },
        { field: 'salesmanship', hide: true, type: 'number', editable: true, headerName: 'Salesmanship', width: 170 },
        { field: 'technique', hide: true, type: 'number', editable: true, headerName: 'Técnica', width: 140 },
        { field: 'visual', hide: true, type: 'number', editable: true, headerName: 'Visual', width: 120 },
        { field: 'approve', width: 150, headerName: 'Aprovar', width: 160,
            renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        fontSize={11}
                        h={"30px"}
                        letterSpacing={1}
                        bgGradient="linear-gradient(to right, #4da6a1, #38a459)"
                        color={'white'}
                        _hover={{
                            bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: "linear-gradient(to right, #4da6a1, #38a459)",
                            boxShadow: 'xl',
                        }}
                        disabled={loading || params.row.stage_status == "submitted"}
                        onClick={() => evaluate1stStage(params.row.login_email, "approved", params.row.first_stage)}
                    >
                        APROVAR
                    </Button>
                    
                );
            }
        },
        { field: 'disapprove', width: 150, headerName: 'Reprovar', width: 160,
            renderCell: (params) => {
                return (
                    <Button 
                        fontFamily={'Poppins'}
                        w={100}
                        fontSize={11}
                        h={"30px"}
                        letterSpacing={1}
                        color={'white'}
                        bgGradient="linear-gradient(to right, #616772, #d54040)"
                        _hover={{
                            bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                            boxShadow: 'xl',
                        }}
                        _active={{
                            bgGradient: 'linear-gradient(to right, #616772, #d54040)',
                            boxShadow: 'xl',
                        }}
                        disabled={loading || params.row.stage_status == "submitted"}
                        onClick={() => evaluate1stStage(params.row.login_email, "disapproved", params.row.first_stage)}
                    >
                        REPROVAR
                    </Button>
                    
                );
            }
        }
    ];

    return [rows, columns]
}

function getAvgScore(data) {
    let avg = 0;
    avg = (parseInt(data.clarity) + parseInt(data.criativity) + parseInt(data.extra_information) + parseInt(data.logical_fundamentals) + parseInt(data.message) + parseInt(data.participation) + parseInt(data.salesmanship) + parseInt(data.technique) + parseInt(data.visual)) / 9;
    return Math.round(avg * 100) / 100
}

function Badge({color, label}) {
    return <Text bgColor={color} fontFamily='Poppins' fontSize={11} textTransform="uppercase" w={100} textAlign="center" fontWeight={600} lineHeight={"30px"} borderRadius={5} h={"30px"} color="white">{label}</Text>
}

export default useChallenge1stStageHR;