
import { Heading, Flex, Button, Table, TableContainer, Thead, Tbody, Tr, Td, Th, Spinner } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AiOutlinePoweroff } from 'react-icons/ai';

import { Header, SideBar, Loading } from '../components'
import { useFetch, useUpdateJob } from '../hooks'

export default function ManageJobs() {

    let navigate = useNavigate()

    let [ status, data ] = useFetch("JOBS", 'jobs/all')

    
    return(
        <Flex minH='100vh' w='100%' p={10} position='relative' bgColor='#efe8d8' align='center' justify='flex-start' direction={'column'} pl={280} pt={85}>
            <SideBar navigate={navigate} />
            <Header title="vagas" />

            <Flex w='100%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={2} pl={8}>
                <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Processos Ativos</Heading>
                <Button
                    fontFamily={'Poppins'}
                    w={150}
                    fontSize={16}
                    letterSpacing={1}
                    bgGradient="linear-gradient(to right, #616772, #3a588d)"
                    color={'white'}
                    _hover={{
                        bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                        boxShadow: 'xl',
                    }}
                    _active={{bgColor:'secondary.main'}}
                    onClick={() => navigate('/createjob')}
                >
                    NOVO
                </Button>
            </Flex>
            {status === 'fetching' ? <Loading w='100%' h='auto' /> : <JobsTable navigate={navigate} type='active' data={data} />}

            <Flex w='100%' h='auto' direction='row' align='center' justify='space-between' pt={12} pb={2} pl={8}>
                <Heading fontWeight={500} color='#222f46de' fontFamily='Poppins' fontSize={26} letterSpacing={2} borderLeft='10px solid #d5a741' pl={4}>Processos Inativos</Heading>
            </Flex>
            {status === 'fetching' ? <Loading w='100%' h='auto' /> : <JobsTable navigate={navigate} type='inactive' data={data} />}
        </Flex>
    );

}


function JobsTable({ data, type, navigate }) {

    let [updated, updateJob] = useUpdateJob()

    let jobs;

    if (type == 'active') {
        jobs = data.filter(filter_active_jobs);
    } else {
        jobs = data.filter(filter_inactive_jobs);
    }

    return(
        <Flex w='100%' h='auto' direction='row' align='center' justify='space-between' pt={5} pb={5} pl={8}>
            <TableContainer w='100%' borderRadius={15} boxShadow='xl' bgColor='#ffffff80' p={5} fontFamily='Poppins'>
                <Table variant='simple'>
                    <Thead>
                        <Tr>
                            <Th>Vaga</Th>
                            <Th>Área</Th>
                            <Th>Tipo</Th>
                            <Th>Detalhes</Th>
                            {type == 'active' ? <Th>Desabilitar</Th> : <Th>Habilitar</Th>}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {jobs.map((job, index) => (
                            <Tr key={job.job_id} fontWeight={200}>
                                <Td fontWeight={600}>{job.job_title}</Td>
                                <Td>{job.area}</Td>
                                <Td>{job.job_type}</Td>
                                <Td>
                                    <Button
                                        fontFamily={'Poppins'}
                                        fontSize={28}
                                        letterSpacing={1}
                                        bgGradient="linear-gradient(to right, #616772, #3a588d)"
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                                            boxShadow: 'xl',
                                        }}
                                        _active={{bgColor:'secondary.main'}}
                                        disabled={updated == 'updating'}
                                        onClick={() => navigate('/job_details/' + job.job_id)}
                                    >
                                        +
                                    </Button>
                                </Td>
                                <Td>
                                    <Button
                                        fontFamily={'Poppins'}
                                        fontSize={22}
                                        letterSpacing={1}
                                        bgColor={type == 'active' ? '#b73d3d' : '#3e9b27'}
                                        color={'white'}
                                        _hover={{
                                            bgGradient: 'linear-gradient(to right, #616772, #3a588d87)',
                                            boxShadow: 'xl',
                                        }}
                                        _active={{bgColor:'secondary.main'}}
                                        disabled={updated == 'updating'}
                                        onClick={() => updateJob({
                                            job_id: job.job_id,
                                            update: {
                                                active: type == 'active' ? false : true
                                            }
                                        })}
                                    >
                                        <AiOutlinePoweroff />
                                    </Button>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        </Flex>
    );

}

function filter_active_jobs(job) {
    return job.active == true;
}

function filter_inactive_jobs(job) {
    return job.active != true;
}